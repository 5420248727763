
const api = {
    // 登录相关
    login:'login',
    checkToken:"checkToken",
    logoutToken:"logoutToken",

    //用户信息
    insertUser:"user/insert",
    deleteUser:"user/delete",
    bacthDelUser:"user/batchDelete",
    updateUser:"user/update",
    getUserList:"user/list",
    updatePwd:"user/updatePwd",
    resetPwd:"user/resetPwd",
    getUserAll:"user/all",


    //登录日志
    insertLoginLog:"loginlog/insert",
    deleteLoginLog:"loginlog/delete",
    bacthDelLoginLog:"loginlog/batchDelete",
    updateLoginLog:"loginlog/update",
    getLoginLogList:"loginlog/list",
    loginAnalysis:"loginlog/loginAnalysis",

    //菜单
    insertSysMenu:"sysmenu/insert",
    deleteSysMenu:"sysmenu/delete",
    bacthDelSysMenu:"sysmenu/batchDelete",
    updateSysMenu:"sysmenu/update",
    getSysMenuList:"sysmenu/list",
    getAllMenu:"sysmenu/getAllMenu",
    getSysMenuParent:"sysmenu/getParent",

    //角色
    insertSysRole:"sysrole/insert",
    deleteSysRole:"sysrole/delete",
    bacthDelSysRole:"sysrole/batchDelete",
    updateSysRole:"sysrole/update",
    getSysRoleList:"sysrole/list",

    //角色菜单
    insertSysRoleMenu:"sysrolemenu/insert",
    deleteSysRoleMenu:"sysrolemenu/delete",
    bacthDelSysRoleMenu:"sysrolemenu/batchDelete",
    updateSysRoleMenu:"sysrolemenu/update",
    getSysRoleMenuList:"sysrolemenu/list",
    getRoleMenulistById:"sysrolemenu/getRoleMenulistById",

    //用户角色
    insertSysUserRole:"sysuserrole/insert",
    deleteSysUserRole:"sysuserrole/delete",
    bacthDelSysUserRole:"sysuserrole/batchDelete",
    updateSysUserRole:"sysuserrole/update",
    getSysUserRoleList:"sysuserrole/list",

    uploadFile:"file/upload",

    //车辆购买
    insertCarbuy:"carbuy/insert",
    deleteCarbuy:"carbuy/delete",
    bacthDelCarbuy:"carbuy/batchDelete",
    updateCarbuy:"carbuy/update",
    getCarbuyList:"carbuy/list",

    //卡片信息
    insertCardinfo:"cardinfo/insert",
    deleteCardinfo:"cardinfo/delete",
    bacthDelCardinfo:"cardinfo/batchDelete",
    updateCardinfo:"cardinfo/update",
    getCardinfoList:"cardinfo/list",

    //车辆信息
    insertCarinfo:"carinfo/insert",
    deleteCarinfo:"carinfo/delete",
    bacthDelCarinfo:"carinfo/batchDelete",
    updateCarinfo:"carinfo/update",
    getCarinfoList:"carinfo/list",
    carinfoUpdPwd:"carinfo/updPwd",
    carinfoUpUsertk:"carinfo/upUsertk",

    //佣金列表
    insertCommission:"commission/insert",
    deleteCommission:"commission/delete",
    bacthDelCommission:"commission/batchDelete",
    updateCommission:"commission/update",
    getCommissionList:"commission/list",

    //国家信息
    insertCountryinfo:"countryinfo/insert",
    deleteCountryinfo:"countryinfo/delete",
    bacthDelCountryinfo:"countryinfo/batchDelete",
    updateCountryinfo:"countryinfo/update",
    getCountryinfoList:"countryinfo/list",
    getCountryinfoAll:"countryinfo/all",

    //折扣码
    insertDiscountcode:"discountcode/insert",
    deleteDiscountcode:"discountcode/delete",
    bacthDelDiscountcode:"discountcode/batchDelete",
    updateDiscountcode:"discountcode/update",
    getDiscountcodeList:"discountcode/list",

    //兑换码
    insertExcode:"excode/insert",
    deleteExcode:"excode/delete",
    bacthDelExcode:"excode/batchDelete",
    updateExcode:"excode/update",
    getExcodeList:"excode/list",
    getExcodeAll:"excode/all",

    //商品套餐
    insertGoodsSku:"goodssku/insert",
    deleteGoodsSku:"goodssku/delete",
    bacthDelGoodsSku:"goodssku/batchDelete",
    updateGoodsSku:"goodssku/update",
    getGoodsSkuList:"goodssku/list",

    //商品信息
    insertGoodsSpu:"goodsspu/insert",
    deleteGoodsSpu:"goodsspu/delete",
    bacthDelGoodsSpu:"goodsspu/batchDelete",
    updateGoodsSpu:"goodsspu/update",
    getGoodsSpuList:"goodsspu/list",
    getGoodsSpuAll:"goodsspu/getall",

    //商品类型
    insertGoodstype:"goodstype/insert",
    deleteGoodstype:"goodstype/delete",
    bacthDelGoodstype:"goodstype/batchDelete",
    updateGoodstype:"goodstype/update",
    getGoodstypeList:"goodstype/list",

    //推荐等级设置
    insertIntrolevel:"introlevel/insert",
    deleteIntrolevel:"introlevel/delete",
    bacthDelIntrolevel:"introlevel/batchDelete",
    updateIntrolevel:"introlevel/update",
    getIntrolevelList:"introlevel/list",
    getIntrolevelAll:"introlevel/all",

    //留言信息
    insertMessage:"message/insert",
    deleteMessage:"message/delete",
    bacthDelMessage:"message/batchDelete",
    updateMessage:"message/update",
    getMessageList:"message/list",
    getMessageUserlist:"message/userlist",

    //订单信息
    insertOrderinfo:"orderinfo/insert",
    deleteOrderinfo:"orderinfo/delete",
    bacthDelOrderinfo:"orderinfo/batchDelete",
    updateOrderinfo:"orderinfo/update",
    getOrderinfoList:"orderinfo/list",
    getOrderData:"orderinfo/getOrderData",
    orderanalysis:"orderinfo/orderanalysis",

    //支付方式
    insertPaytype:"paytype/insert",
    deletePaytype:"paytype/delete",
    bacthDelPaytype:"paytype/batchDelete",
    updatePaytype:"paytype/update",
    getPaytypeList:"paytype/list",

    //奖品抽奖池
    insertPrizepool:"prizepool/insert",
    deletePrizepool:"prizepool/delete",
    bacthDelPrizepool:"prizepool/batchDelete",
    updatePrizepool:"prizepool/update",
    getPrizepoolList:"prizepool/list",

    //风控内容描述
    insertRiskcontent:"riskcontent/insert",
    deleteRiskcontent:"riskcontent/delete",
    bacthDelRiskcontent:"riskcontent/batchDelete",
    updateRiskcontent:"riskcontent/update",
    getRiskcontentList:"riskcontent/list",
    getRiskcontentAll:"riskcontent/all",

    //风控名单
    insertRisklist:"risklist/insert",
    deleteRisklist:"risklist/delete",
    bacthDelRisklist:"risklist/batchDelete",
    updateRisklist:"risklist/update",
    getRisklistList:"risklist/list",

    //签到信息
    insertSignininfo:"signininfo/insert",
    deleteSignininfo:"signininfo/delete",
    bacthDelSignininfo:"signininfo/batchDelete",
    updateSignininfo:"signininfo/update",
    getSignininfoList:"signininfo/list",

    //配置信息
    insertSystemconfig:"systemconfig/insert",
    deleteSystemconfig:"systemconfig/delete",
    bacthDelSystemconfig:"systemconfig/batchDelete",
    updateSystemconfig:"systemconfig/update",
    getSystemconfigList:"systemconfig/list",

    //车票信息
    insertTicket:"ticket/insert",
    deleteTicket:"ticket/delete",
    bacthDelTicket:"ticket/batchDelete",
    updateTicket:"ticket/update",
    getTicketList:"ticket/list",
    disabledTicket:"ticket/disabled",
    sendExpiredemail:'user/sendExpiredcode',
    //用户兑换码
    insertUserexcode:"userexcode/insert",
    deleteUserexcode:"userexcode/delete",
    bacthDelUserexcode:"userexcode/batchDelete",
    updateUserexcode:"userexcode/update",
    getUserexcodeList:"userexcode/list",

    //抽奖记录
    insertWinprize:"winprize/insert",
    deleteWinprize:"winprize/delete",
    bacthDelWinprize:"winprize/batchDelete",
    updateWinprize:"winprize/update",
    getWinprizeList:"winprize/list",

    //提现申请记录
    insertWithdrawal:"withdrawal/insert",
    deleteWithdrawal:"withdrawal/delete",
    bacthDelWithdrawal:"withdrawal/batchDelete",
    updateWithdrawal:"withdrawal/update",
    getWithdrawalList:"withdrawal/list",

    //工单类型
    insertWordertype:"wordertype/insert",
    deleteWordertype:"wordertype/delete",
    bacthDelWordertype:"wordertype/batchDelete",
    updateWordertype:"wordertype/update",
    getWordertypeList:"wordertype/list",
    getWordertypeAll:"wordertype/all",

    //工单
    insertWorkorder:"workorder/insert",
    deleteWorkorder:"workorder/delete",
    bacthDelWorkorder:"workorder/batchDelete",
    updateWorkorder:"workorder/update",
    getWorkorderList:"workorder/list",

    //帮助详情
    insertHelpdetail:"helpdetail/insert",
    deleteHelpdetail:"helpdetail/delete",
    bacthDelHelpdetail:"helpdetail/batchDelete",
    updateHelpdetail:"helpdetail/update",
    getHelpdetailList:"helpdetail/list",

    //帮助分类
    insertHelptype:"helptype/insert",
    deleteHelptype:"helptype/delete",
    bacthDelHelptype:"helptype/batchDelete",
    updateHelptype:"helptype/update",
    getHelptypeList:"helptype/list",

    //支付订单
    insertPayorder:"payorder/insert",
    deletePayorder:"payorder/delete",
    bacthDelPayorder:"payorder/batchDelete",
    updatePayorder:"payorder/update",
    getPayorderList:"payorder/list",

    //省份信息
    insertProvince:"province/insert",
    deleteProvince:"province/delete",
    bacthDelProvince:"province/batchDelete",
    updateProvince:"province/update",
    getProvinceList:"province/list",

    //用户信用卡
    insertUsercard:"usercard/insert",
    deleteUsercard:"usercard/delete",
    bacthDelUsercard:"usercard/batchDelete",
    updateUsercard:"usercard/update",
    getUsercardList:"usercard/list",
    getUsercardAll:"usercard/all",
    getUsercardById:"usercard/getById",

    //订单退款
    insertOrderrefund:"orderrefund/insert",
    deleteOrderrefund:"orderrefund/delete",
    bacthDelOrderrefund:"orderrefund/batchDelete",
    updateOrderrefund:"orderrefund/update",
    getOrderrefundList:"orderrefund/list",
    getOrderrefundAll:"orderrefund/all",
    getOrderrefundById:"orderrefund/getById",
    orderrefundPass:"orderrefund/pass",
    orderrefundClose:"orderrefund/close",

    //兑换码赠送
    insertUserexcodegive:"userexcodegive/insert",
    deleteUserexcodegive:"userexcodegive/delete",
    bacthDelUserexcodegive:"userexcodegive/batchDelete",
    updateUserexcodegive:"userexcodegive/update",
    getUserexcodegiveList:"userexcodegive/list",
    getUserexcodegiveAll:"userexcodegive/all",
    getUserexcodegiveById:"userexcodegive/getById",

    //员工绑定用户
    insertBinduser:"binduser/insert",
    deleteBinduser:"binduser/delete",
    bacthDelBinduser:"binduser/batchDelete",
    updateBinduser:"binduser/update",
    getBinduserList:"binduser/list",
    getBinduserAll:"binduser/all",
    getBinduserById:"binduser/getById",
    getStaffStatisData:"binduser/getStaffStatisData",

    //车票密码
    insertTicketpwd:"ticketpwd/insert",
    deleteTicketpwd:"ticketpwd/delete",
    bacthDelTicketpwd:"ticketpwd/batchDelete",
    updateTicketpwd:"ticketpwd/update",
    getTicketpwdList:"ticketpwd/list",
    getTicketpwdAll:"ticketpwd/all",
    getTicketpwdById:"ticketpwd/getById",

}

export default api;
