import Vue from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import router from '@/router/index';
import Print from 'vue-print-nb'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import { axiosinstaller } from '@/request/request';
import ElFormRenderer from '@femessage/el-form-renderer'
import ElDataTable from '@femessage/el-data-table'
import echarts from 'echarts'
import {hasPermission} from "./util/hasPermission";
import {MessageBox} from 'element-ui'
import Moment from 'moment'


Vue.prototype.$moment = Moment
Vue.prototype.$confirmel = MessageBox.confirm;
Vue.prototype.$echarts = echarts
Vue.use(axiosinstaller);
Vue.prototype.$axios = axios;
Vue.use(Element)
Vue.use(Antd);
Vue.use(Print);
Vue.component('el-form-renderer', ElFormRenderer)
Vue.component('el-data-table', ElDataTable)

//全局的权限校验
Vue.prototype.hasPerm = hasPermission
Vue.config.productionTip = false
axios.defaults.baseURL = '/api'


new Vue({
  router,
  render: h => h(App),
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin: function () {
      window.console.log("---------main")
      let deadline = sessionStorage.getItem('deadline');
      let userInfo = sessionStorage.getItem("userInfo");
      let token = sessionStorage.getItem('token');
      let now = new Date().getTime();
      if(!deadline||!userInfo||!token) {
        this.$router.push({ path: "/login" });
      }else{
        if (deadline<now){
          this.$router.push({ path: "/login" });
        } else {
          this.$router.push({ path: "/backhome" });
        }
      }
    }
  }
}).$mount('#app')
