import API from './API'
import { axios } from '@/request/request.js'

export function Login(data) {
    return axios.post(`${API.login}`,data)
}
export function checkToken(data) {
    return axios.post(`${API.checkToken}`,data)
}
export function logoutToken() {
    return axios.get(`${API.logoutToken}`)
}
//用户信息
export function insertUser(data) {
    return axios.post(`${API.insertUser}`,data)
}
export function updateUser(data) {
    return axios.put(`${API.updateUser}`,data)
}
export function deleteUser(data) {
    return axios.delete(`${API.deleteUser}?id=`+data)
}
export function bacthDelUser(data) {
    return axios.post(`${API.bacthDelUser}`,data)
}
export function getUserAllByOrdinary() {
    return axios.get(`${API.getUserAll}?usertype=23`)
}
export function getUserAllByStaff() {
    return axios.get(`${API.getUserAll}?usertype=21`)
}
export function updatePwd(data) {
    return axios.put(`${API.updatePwd}`,data)
}
export function resetPwd(data) {
    return axios.put(`${API.resetPwd}`,data)
}
export function getUserByid(data) {
    return axios.get(`${API.getUserList}?id=`+data)
}
export function getUserAll() {
    return axios.get(`${API.getUserAll}`)
}

//登录日志
export function insertLoginLog(data) {
    return axios.post(`${API.insertLoginLog}`,data)
}
export function updateLoginLog(data) {
    return axios.put(`${API.updateLoginLog}`,data)
}
export function deleteLoginLog(data) {
    return axios.delete(`${API.deleteLoginLog}?id=`+data)
}
export function bacthDelLoginLog(data) {
    return axios.post(`${API.bacthDelLoginLog}`,data)
}
export function loginAnalysis() {
    return axios.get(`${API.loginAnalysis}`)
}


//菜单
export function insertSysMenu(data) {
    return axios.post(`${API.insertSysMenu}`,data)
}
export function updateSysMenu(data) {
    return axios.put(`${API.updateSysMenu}`,data)
}
export function deleteSysMenu(data) {
    return axios.delete(`${API.deleteSysMenu}?id=`+data)
}
export function bacthDelSysMenu(data) {
    return axios.post(`${API.bacthDelSysMenu}`,data)
}
export function getAllMenu() {
    return axios.get(`${API.getAllMenu}`)
}
export function getSysMenuParent() {
    return axios.get(`${API.getSysMenuParent}`)
}

//角色
export function insertSysRole(data) {
    return axios.post(`${API.insertSysRole}`,data)
}
export function updateSysRole(data) {
    return axios.put(`${API.updateSysRole}`,data)
}
export function deleteSysRole(data) {
    return axios.delete(`${API.deleteSysRole}?id=`+data)
}
export function bacthDelSysRole(data) {
    return axios.post(`${API.bacthDelSysRole}`,data)
}
export function getSysRoleList() {
    return axios.get(`${API.getSysRoleList}`)
}
// 发送过期邮件提醒
export function sendExpiredemail(data){
    return axios.get(`${API.sendExpiredemail}?email=`+data)
}
// 换车
export function updateUserTicke(carid,id) {
    return axios.put(`${API.carinfoUpUsertk}?ID=`+id+`&carid=`+carid)
}
//角色菜单
export function insertSysRoleMenu(data) {
    return axios.post(`${API.insertSysRoleMenu}`,data)
}
export function updateSysRoleMenu(data) {
    return axios.put(`${API.updateSysRoleMenu}`,data)
}
export function deleteSysRoleMenu(data) {
    return axios.delete(`${API.deleteSysRoleMenu}?id=`+data)
}
export function bacthDelSysRoleMenu(data) {
    return axios.post(`${API.bacthDelSysRoleMenu}`,data)
}
export function getRoleMenulistById(data) {
    return axios.get(`${API.getRoleMenulistById}?id=`+data)
}

//用户角色
export function insertSysUserRole(data) {
    return axios.post(`${API.insertSysUserRole}`,data)
}
export function updateSysUserRole(data) {
    return axios.put(`${API.updateSysUserRole}`,data)
}
export function deleteSysUserRole(data) {
    return axios.delete(`${API.deleteSysUserRole}?id=`+data)
}
export function bacthDelSysUserRole(data) {
    return axios.post(`${API.bacthDelSysUserRole}`,data)
}

export function uploadFile(data) {
    return axios.post(`${API.uploadFile}`,data)
}

export function downFile(url,parameter){
    return axios({
        url: url,
        params: parameter,
        method:'get' ,
        responseType: 'blob'
    })
}

//车辆购买
export function insertCarbuy(data) {
    return axios.post(`${API.insertCarbuy}`,data)
}
export function updateCarbuy(data) {
    return axios.put(`${API.updateCarbuy}`,data)
}
export function deleteCarbuy(data) {
    return axios.delete(`${API.deleteCarbuy}?id=`+data)
}
export function bacthDelCarbuy(data) {
    return axios.post(`${API.bacthDelCarbuy}`,data)
}
//卡片信息
export function insertCardinfo(data) {
    return axios.post(`${API.insertCardinfo}`,data)
}
export function updateCardinfo(data) {
    return axios.put(`${API.updateCardinfo}`,data)
}
export function deleteCardinfo(data) {
    return axios.delete(`${API.deleteCardinfo}?id=`+data)
}
export function bacthDelCardinfo(data) {
    return axios.post(`${API.bacthDelCardinfo}`,data)
}
//车辆信息
export function insertCarinfo(data) {
    return axios.post(`${API.insertCarinfo}`,data)
}
export function updateCarinfo(data) {
    return axios.put(`${API.updateCarinfo}`,data)
}
export function deleteCarinfo(data) {
    return axios.delete(`${API.deleteCarinfo}?id=`+data)
}
export function bacthDelCarinfo(data) {
    return axios.post(`${API.bacthDelCarinfo}`,data)
}
export function carinfoUpdPwd(data) {
    return axios.put(`${API.carinfoUpdPwd}`,data)
}
export function getCarinfoList() {
    return axios.get(`${API.getCarinfoList}?size=`+10000)
}



//佣金列表
export function insertCommission(data) {
    return axios.post(`${API.insertCommission}`,data)
}
export function updateCommission(data) {
    return axios.put(`${API.updateCommission}`,data)
}
export function deleteCommission(data) {
    return axios.delete(`${API.deleteCommission}?id=`+data)
}
export function bacthDelCommission(data) {
    return axios.post(`${API.bacthDelCommission}`,data)
}
//国家信息
export function insertCountryinfo(data) {
    return axios.post(`${API.insertCountryinfo}`,data)
}
export function updateCountryinfo(data) {
    return axios.put(`${API.updateCountryinfo}`,data)
}
export function deleteCountryinfo(data) {
    return axios.delete(`${API.deleteCountryinfo}?id=`+data)
}
export function bacthDelCountryinfo(data) {
    return axios.post(`${API.bacthDelCountryinfo}`,data)
}
export function getCountryinfoAll() {
    return axios.get(`${API.getCountryinfoAll}`)
}

//折扣码
export function insertDiscountcode(data) {
    return axios.post(`${API.insertDiscountcode}`,data)
}
export function updateDiscountcode(data) {
    return axios.put(`${API.updateDiscountcode}`,data)
}
export function deleteDiscountcode(data) {
    return axios.delete(`${API.deleteDiscountcode}?id=`+data)
}
export function bacthDelDiscountcode(data) {
    return axios.post(`${API.bacthDelDiscountcode}`,data)
}
//兑换码
export function insertExcode(data) {
    return axios.post(`${API.insertExcode}`,data)
}
export function updateExcode(data) {
    return axios.put(`${API.updateExcode}`,data)
}
export function deleteExcode(data) {
    return axios.delete(`${API.deleteExcode}?id=`+data)
}
export function bacthDelExcode(data) {
    return axios.post(`${API.bacthDelExcode}`,data)
}
export function getExcodeList() {
    return axios.get(`${API.getExcodeAll}`)
}
export function getExcodeAllByUser() {
    return axios.get(`${API.getExcodeAll}?isuse=1`)
}

//商品套餐
export function insertGoodsSku(data) {
    return axios.post(`${API.insertGoodsSku}`,data)
}
export function updateGoodsSku(data) {
    return axios.put(`${API.updateGoodsSku}`,data)
}
export function deleteGoodsSku(data) {
    return axios.delete(`${API.deleteGoodsSku}?id=`+data)
}
export function bacthDelGoodsSku(data) {
    return axios.post(`${API.bacthDelGoodsSku}`,data)
}
export function getGoodsSkuList(goodsid) {
    return axios.get(`${API.getGoodsSkuList}?size=10000&goodsid=`+goodsid)
}

//商品信息
export function insertGoodsSpu(data) {
    return axios.post(`${API.insertGoodsSpu}`,data)
}
export function updateGoodsSpu(data) {
    return axios.put(`${API.updateGoodsSpu}`,data)
}
export function deleteGoodsSpu(data) {
    return axios.delete(`${API.deleteGoodsSpu}?id=`+data)
}
export function bacthDelGoodsSpu(data) {
    return axios.post(`${API.bacthDelGoodsSpu}`,data)
}
export function getGoodsSpuList() {
    return axios.get(`${API.getGoodsSpuList}?size=10000`)
}
export function getGoodsSpuAll() {
    return axios.get(`${API.getGoodsSpuAll}`)
}


//商品类型
export function insertGoodstype(data) {
    return axios.post(`${API.insertGoodstype}`,data)
}
export function updateGoodstype(data) {
    return axios.put(`${API.updateGoodstype}`,data)
}
export function deleteGoodstype(data) {
    return axios.delete(`${API.deleteGoodstype}?id=`+data)
}
export function bacthDelGoodstype(data) {
    return axios.post(`${API.bacthDelGoodstype}`,data)
}
export function getGoodstypeList() {
    return axios.get(`${API.getGoodstypeList}?size=10000`)
}


//推荐等级设置
export function insertIntrolevel(data) {
    return axios.post(`${API.insertIntrolevel}`,data)
}
export function updateIntrolevel(data) {
    return axios.put(`${API.updateIntrolevel}`,data)
}
export function deleteIntrolevel(data) {
    return axios.delete(`${API.deleteIntrolevel}?id=`+data)
}
export function bacthDelIntrolevel(data) {
    return axios.post(`${API.bacthDelIntrolevel}`,data)
}
export function getIntrolevelAll() {
    return axios.get(`${API.getIntrolevelAll}`)
}


//留言信息
export function insertMessage(data) {
    return axios.post(`${API.insertMessage}`,data)
}
export function updateMessage(data) {
    return axios.put(`${API.updateMessage}`,data)
}
export function deleteMessage(data) {
    return axios.delete(`${API.deleteMessage}?id=`+data)
}
export function bacthDelMessage(data) {
    return axios.post(`${API.bacthDelMessage}`,data)
}
export function getMessageUserlist() {
    return axios.get(`${API.getMessageUserlist}`)
}

//订单信息
export function insertOrderinfo(data) {
    return axios.post(`${API.insertOrderinfo}`,data)
}
export function updateOrderinfo(data) {
    return axios.put(`${API.updateOrderinfo}`,data)
}
export function deleteOrderinfo(data) {
    return axios.delete(`${API.deleteOrderinfo}?id=`+data)
}
export function bacthDelOrderinfo(data) {
    return axios.post(`${API.bacthDelOrderinfo}`,data)
}
export function getOrderData(data) {
    return axios.post(`${API.getOrderData}`,data)
}
export function orderanalysis(data) {
    return axios.post(`${API.orderanalysis}`,data)
}

//支付方式
export function insertPaytype(data) {
    return axios.post(`${API.insertPaytype}`,data)
}
export function updatePaytype(data) {
    return axios.put(`${API.updatePaytype}`,data)
}
export function deletePaytype(data) {
    return axios.delete(`${API.deletePaytype}?id=`+data)
}
export function bacthDelPaytype(data) {
    return axios.post(`${API.bacthDelPaytype}`,data)
}
//奖品抽奖池
export function insertPrizepool(data) {
    return axios.post(`${API.insertPrizepool}`,data)
}
export function updatePrizepool(data) {
    return axios.put(`${API.updatePrizepool}`,data)
}
export function deletePrizepool(data) {
    return axios.delete(`${API.deletePrizepool}?id=`+data)
}
export function bacthDelPrizepool(data) {
    return axios.post(`${API.bacthDelPrizepool}`,data)
}
//风控内容描述
export function insertRiskcontent(data) {
    return axios.post(`${API.insertRiskcontent}`,data)
}
export function updateRiskcontent(data) {
    return axios.put(`${API.updateRiskcontent}`,data)
}
export function deleteRiskcontent(data) {
    return axios.delete(`${API.deleteRiskcontent}?id=`+data)
}
export function bacthDelRiskcontent(data) {
    return axios.post(`${API.bacthDelRiskcontent}`,data)
}
export function getRiskcontentAll() {
    return axios.get(`${API.getRiskcontentAll}`)
}

//风控名单
export function insertRisklist(data) {
    return axios.post(`${API.insertRisklist}`,data)
}
export function updateRisklist(data) {
    return axios.put(`${API.updateRisklist}`,data)
}
export function deleteRisklist(data) {
    return axios.delete(`${API.deleteRisklist}?id=`+data)
}
export function bacthDelRisklist(data) {
    return axios.post(`${API.bacthDelRisklist}`,data)
}
//签到信息
export function insertSignininfo(data) {
    return axios.post(`${API.insertSignininfo}`,data)
}
export function updateSignininfo(data) {
    return axios.put(`${API.updateSignininfo}`,data)
}
export function deleteSignininfo(data) {
    return axios.delete(`${API.deleteSignininfo}?id=`+data)
}
export function bacthDelSignininfo(data) {
    return axios.post(`${API.bacthDelSignininfo}`,data)
}
//配置信息
export function insertSystemconfig(data) {
    return axios.post(`${API.insertSystemconfig}`,data)
}
export function updateSystemconfig(data) {
    return axios.put(`${API.updateSystemconfig}`,data)
}
export function deleteSystemconfig(data) {
    return axios.delete(`${API.deleteSystemconfig}?id=`+data)
}
export function bacthDelSystemconfig(data) {
    return axios.post(`${API.bacthDelSystemconfig}`,data)
}
//车票信息
export function insertTicket(data) {
    return axios.post(`${API.insertTicket}`,data)
}
export function updateTicket(data) {
    return axios.put(`${API.updateTicket}`,data)
}
export function deleteTicket(data) {
    return axios.delete(`${API.deleteTicket}?id=`+data)
}
export function bacthDelTicket(data) {
    return axios.post(`${API.bacthDelTicket}`,data)
}
export function disabledTicket(data) {
    return axios.post(`${API.disabledTicket}`,data)
}

//用户兑换码
export function insertUserexcode(data) {
    return axios.post(`${API.insertUserexcode}`,data)
}
export function updateUserexcode(data) {
    return axios.put(`${API.updateUserexcode}`,data)
}
export function deleteUserexcode(data) {
    return axios.delete(`${API.deleteUserexcode}?id=`+data)
}
export function bacthDelUserexcode(data) {
    return axios.post(`${API.bacthDelUserexcode}`,data)
}
//抽奖记录
export function insertWinprize(data) {
    return axios.post(`${API.insertWinprize}`,data)
}
export function updateWinprize(data) {
    return axios.put(`${API.updateWinprize}`,data)
}
export function deleteWinprize(data) {
    return axios.delete(`${API.deleteWinprize}?id=`+data)
}
export function bacthDelWinprize(data) {
    return axios.post(`${API.bacthDelWinprize}`,data)
}
//提现申请记录
export function insertWithdrawal(data) {
    return axios.post(`${API.insertWithdrawal}`,data)
}
export function updateWithdrawal(data) {
    return axios.put(`${API.updateWithdrawal}`,data)
}
export function deleteWithdrawal(data) {
    return axios.delete(`${API.deleteWithdrawal}?id=`+data)
}
export function bacthDelWithdrawal(data) {
    return axios.post(`${API.bacthDelWithdrawal}`,data)
}
//工单类型
export function insertWordertype(data) {
    return axios.post(`${API.insertWordertype}`,data)
}
export function updateWordertype(data) {
    return axios.put(`${API.updateWordertype}`,data)
}
export function deleteWordertype(data) {
    return axios.delete(`${API.deleteWordertype}?id=`+data)
}
export function bacthDelWordertype(data) {
    return axios.post(`${API.bacthDelWordertype}`,data)
}
export function getWordertypeAll() {
    return axios.get(`${API.getWordertypeAll}`)
}

//工单
export function insertWorkorder(data) {
    return axios.post(`${API.insertWorkorder}`,data)
}
export function updateWorkorder(data) {
    return axios.put(`${API.updateWorkorder}`,data)
}
export function deleteWorkorder(data) {
    return axios.delete(`${API.deleteWorkorder}?id=`+data)
}
export function bacthDelWorkorder(data) {
    return axios.post(`${API.bacthDelWorkorder}`,data)
}
//帮助分类
export function insertHelptype(data) {
    return axios.post(`${API.insertHelptype}`,data)
}
export function updateHelptype(data) {
    return axios.put(`${API.updateHelptype}`,data)
}
export function deleteHelptype(data) {
    return axios.delete(`${API.deleteHelptype}?id=`+data)
}
export function bacthDelHelptype(data) {
    return axios.post(`${API.bacthDelHelptype}`,data)
}
export function getHelptypeList() {
    return axios.get(`${API.getHelptypeList}?size=10000`)
}

//帮助详情
export function insertHelpdetail(data) {
    return axios.post(`${API.insertHelpdetail}`,data)
}
export function updateHelpdetail(data) {
    return axios.put(`${API.updateHelpdetail}`,data)
}
export function deleteHelpdetail(data) {
    return axios.delete(`${API.deleteHelpdetail}?id=`+data)
}
export function bacthDelHelpdetail(data) {
    return axios.post(`${API.bacthDelHelpdetail}`,data)
}
//支付订单
export function insertPayorder(data) {
    return axios.post(`${API.insertPayorder}`,data)
}
export function updatePayorder(data) {
    return axios.put(`${API.updatePayorder}`,data)
}
export function deletePayorder(data) {
    return axios.delete(`${API.deletePayorder}?id=`+data)
}
export function bacthDelPayorder(data) {
    return axios.post(`${API.bacthDelPayorder}`,data)
}
//省份信息
export function insertProvince(data) {
    return axios.post(`${API.insertProvince}`,data)
}
export function updateProvince(data) {
    return axios.put(`${API.updateProvince}`,data)
}
export function deleteProvince(data) {
    return axios.delete(`${API.deleteProvince}?id=`+data)
}
export function bacthDelProvince(data) {
    return axios.post(`${API.bacthDelProvince}`,data)
}

//用户信用卡
export function insertUsercard(data) {
    return axios.post(`${API.insertUsercard}`,data)
}
export function updateUsercard(data) {
    return axios.put(`${API.updateUsercard}`,data)
}
export function deleteUsercard(data) {
    return axios.delete(`${API.deleteUsercard}?id=`+data)
}
export function bacthDelUsercard(data) {
    return axios.post(`${API.bacthDelUsercard}`,data)
}
export function getUsercardAll() {
    return axios.get(`${API.getUsercardAll}`)
}
export function getUsercardById(data) {
    return axios.get(`${API.getUsercardById}?id=`+data)
}
//订单退款
export function insertOrderrefund(data) {
    return axios.post(`${API.insertOrderrefund}`,data)
}
export function updateOrderrefund(data) {
    return axios.put(`${API.updateOrderrefund}`,data)
}
export function deleteOrderrefund(data) {
    return axios.delete(`${API.deleteOrderrefund}?id=`+data)
}
export function bacthDelOrderrefund(data) {
    return axios.post(`${API.bacthDelOrderrefund}`,data)
}
export function getOrderrefundAll() {
    return axios.get(`${API.getOrderrefundAll}`)
}
export function getOrderrefundById(data) {
    return axios.get(`${API.getOrderrefundById}?id=`+data)
}
export function orderrefundPass(data) {
    return axios.post(`${API.orderrefundPass}`,data)
}
export function orderrefundClose(data) {
    return axios.post(`${API.orderrefundClose}`,data)
}
//兑换码赠送
export function insertUserexcodegive(data) {
    return axios.post(`${API.insertUserexcodegive}`,data)
}
export function updateUserexcodegive(data) {
    return axios.put(`${API.updateUserexcodegive}`,data)
}
export function deleteUserexcodegive(data) {
    return axios.delete(`${API.deleteUserexcodegive}?id=`+data)
}
export function bacthDelUserexcodegive(data) {
    return axios.post(`${API.bacthDelUserexcodegive}`,data)
}
export function getUserexcodegiveAll() {
    return axios.get(`${API.getUserexcodegiveAll}`)
}
export function getUserexcodegiveById(data) {
    return axios.get(`${API.getUserexcodegiveById}?id=`+data)
}
//员工绑定用户
export function insertBinduser(data) {
    return axios.post(`${API.insertBinduser}`,data)
}
export function updateBinduser(data) {
    return axios.put(`${API.updateBinduser}`,data)
}
export function deleteBinduser(data) {
    return axios.delete(`${API.deleteBinduser}?id=`+data)
}
export function bacthDelBinduser(data) {
    return axios.post(`${API.bacthDelBinduser}`,data)
}
export function getBinduserAll() {
    return axios.get(`${API.getBinduserAll}`)
}
export function getBinduserById(data) {
    return axios.get(`${API.getBinduserById}?id=`+data)
}
export function getStaffStatisData(data) {
    return axios.post(`${API.getStaffStatisData}`,data)
}
//车票密码
export function insertTicketpwd(data) {
    return axios.post(`${API.insertTicketpwd}`,data)
}
export function updateTicketpwd(data) {
    return axios.put(`${API.updateTicketpwd}`,data)
}
export function deleteTicketpwd(data) {
    return axios.delete(`${API.deleteTicketpwd}?id=`+data)
}
export function bacthDelTicketpwd(data) {
    return axios.post(`${API.bacthDelTicketpwd}`,data)
}
export function getTicketpwdAll() {
    return axios.get(`${API.getTicketpwdAll}`)
}
export function getTicketpwdById(data) {
    return axios.get(`${API.getTicketpwdById}?id=`+data)
}
