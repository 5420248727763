<template>
  <a-config-provider :locale="zhCN">
    <div id="app" style="height: 100%">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import moment from 'moment';
  import 'moment/locale/zh-cn';
  moment.locale('zh-cn');

  export default {
    data() {
      return {
        zhCN,
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;
    color: #2c3e50;
    height: 100%;
    .shop-compare-table{
      .ant-table-fixed-header .ant-table-scroll .ant-table-header{
        margin-bottom: -17px !important;
        padding-bottom: 0px !important;
      }
      .ant-table-tbody > tr > td {
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    .editable-add-btn{
      margin-bottom: 10px;
    }
    .mgr-btm-10{
      margin-bottom: 10px;
    }
    .hide-clear{
      .ant-select-selection:hover .ant-select-selection__clear{
        opacity: 0;
      }
    }
  }
</style>
