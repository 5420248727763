<template>
  <a-menu
          :defaultSelectedKeys="[selectedIndex]"
          v-model="selectedMenu"
          theme="dark"
          mode="inline"
          :inlineCollapsed="collapsed"
          :open-keys="openKeys"
          @click="handleClick"
          @openChange="onOpenChange"
          class="amenu"
  >
    <a-sub-menu v-for="menuItem of menuListWithAuth" :key="menuItem.id" class="menu__item">
      <span slot="title"><icon-font :type="menuItem.icon" /><span>{{menuItem.name}}</span></span>
      <a-menu-item v-for="menuchild of menuItem.sysMenuChild" :key="menuchild.url">
        <span class="menu__item">{{menuchild.name}}</span>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
  import { Icon } from 'ant-design-vue';

  const IconFont = Icon.createFromIconfontCN({
    scriptUrl: 'https://at.alicdn.com/t/c/font_3750431_3j4m4k5vnhl.js',
  });
  export default {
    components: {
      IconFont,
    },
    inject: ['reload'],
    name:'Sidemenu',
    props:{
      menuIndex: {
        type: Number,
        default: 0
      },
      selectedIndex :{
        type: String,
        default: 'page'
      },
      selectedKeys:{
        type: String,
        default: 'page'
      },
      collapsed: {
        type: Boolean,
        default: true
      }
    },
    watch: {
      selectedKeys(value) {
        if(value) {
          this.selectedMenu = value;
        }
      },
    },
    data() {
      return {
        menuList: [],
        selectedMenu:["page"],
        rootSubmenuKeys:[],
        openKeys:[]
      }
    },
    methods: {
      handleClick({ key}) {
        this.reload();
        this.$router.push(key);
      },
      onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
    },
    computed: {
      menuListWithAuth() {
        let that=this
        if(!that.menuList) return [];
        let menuInfo=JSON.parse(sessionStorage.getItem("menuList"));
        menuInfo.forEach(r=>{
          that.rootSubmenuKeys.push(r.id)
        })
        return menuInfo
      },
    },
    mounted(){
      this.$router.push('page');
    }
  };
</script>

<style lang="less" scoped>
  .amenu{
    background-color: #304156 !important;
  }
  .menu__item{
    font-size: 14px;
    font-weight: bolder;
  }
</style>
