<template>
  <a-dropdown  class="dropdown">
    <div class="info">
      <span class="info-text">
        <a-avatar icon="user" size="small"/>
        <span class="info-name">{{name}}</span>
      </span>
    </div>
    <a-menu slot="overlay" class="logout">
        <a-menu-item key="1"  @click="updatePwd">
            <a-icon type="team"/>
            <span>修改密码</span>
        </a-menu-item>
        <a-menu-divider />
      <a-menu-item key="2" @click="handleLogout">
        <a-icon type="logout"/>
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>
<script>
import { logoutToken } from '@/request/requests'

export default {
  name: "Topbar",
  props: {
      name: String,
      userId: String,
  },
  data() {
    return {};
  },
  methods: {
      updatePwd(){
          this.$router.push({ path: "updatepwd" });
      },
     async handleLogout() {
      let that = this;
      this.$confirm({
        title: "提示",
        content: "确认要退出登录吗?",
        async onOk() {
          try {
            sessionStorage.removeItem("userInfo");
            sessionStorage.removeItem("deadline");
            await logoutToken();
            sessionStorage.removeItem("token");
          }catch (err) {
            window.console.log(err)
          }
          that.$router.push({ path: "/login" })
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style lang="less">
.logout {
  cursor: pointer;
}
.info {
  cursor: pointer;
  float: right;
  height: 50px;
  color: #000;
  width: 70px;
  position: relative;
  text-align: center;

  .info-img {
    img {
      height: 50px;
    }
  }
  .info-text {
    position: absolute;
    right: 0;
    .info-dept,
    .info-name,
    .info-logout {
      margin-left: 10px;
    }
  }
}
</style>
