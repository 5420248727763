<template>
    <a-layout id="layout">
        <a-layout-sider :trigger="null" collapsible v-model="collapsed" class="slider">
            <div class="logo">
                <span class="logo_icon">
                  <img src="../../assets/logo.png" alt>
                </span>
                <h1 style="font-weight: bolder">后台管理</h1>
            </div>
            <Sidemenu :selected-index="selectedIndex" :selected-keys="selectedKeys" :collapsed="collapsed"></Sidemenu>
        </a-layout-sider>

        <a-layout>
            <a-layout-header class="header">
                <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="()=> collapsed = !collapsed"/>
                <Topbar :name="name"></Topbar>
            </a-layout-header>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff',  }">
                <router-view  v-if="isRouterAlive"></router-view>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
    import Topbar from "@/components/Topbar/Topbar";
    import Sidemenu from "@/components/Menu/Menu";
    export default {
        name: "Layout",
        components: {
            Topbar,
            Sidemenu
        },
        provide () {
            return {
                reload: this.reload
            }
        },
        created() {
            this.checkToken();
        },
        data() {
            return {
                selectedIndex: "page",
                selectedKeys:"page",
                name: "",
                collapsed: false,
                isRouterAlive: true
            };
        },
        methods: {
            initData() {
                let info = sessionStorage.getItem("userInfo");
                if (info) {
                    let {name} = JSON.parse(info);
                    this.name = name;
                }
            },
            reload () {
                this.isRouterAlive = false
                this.$nextTick(() => (this.isRouterAlive = true))
            },
            async checkToken(){
                window.console.log("---------layout")
                let now = new Date().getTime();
                let deadline = sessionStorage.getItem("deadline");
                let token = sessionStorage.getItem("token");
                let userInfo = sessionStorage.getItem("userInfo");
                if(!deadline||!token||!userInfo){
                    return this.$router.push({ path: "/login" });
                }
                if (deadline<now){
                    return this.$router.push({ path: "/login" });
                }
                this.initData();
            }

        }
    };
</script>
<style>
    html,body{
        width: 100%;
        height: 100%;
        font-size: 12px;
    }
    #layout{
        height: 100%;
    }
    #layout .header {
        padding: 0 20px 0 0;
        background: #fff;
        /*box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);*/
    }
    #layout .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }
    #layout .trigger:hover {
        color: #1890ff;
    }
    .slider {
        box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    }
    #layout .logo {
        transition: all 0.3s;
        display: flex;
        height: 66px;
        background: #000;
        padding: 10px 0 10px 24px;
        overflow: hidden;
    }
    #layout .logo .logo_icon img {
        height: 50px;
    }
    #layout .logo h1 {
        color: #fff;
        font-size: 20px;
        padding: 8px 0 0 24px;
    }
    .layout_menu_item{
        line-height: 60px!important;
        height: 60px!important;
    }
</style>
