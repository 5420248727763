<template>
    <div class="container">
        <div style="background: rgba(0,0,0,.5);width: 40%;max-width: 300px;margin-left: 60%;padding: 20px 0;">
            <h3 style="color: white"> 后台管理中心</h3>
            <a-form id="login" :form="form" class="login-form" @submit="handleLogin">
                <a-form-item>
                    <a-input size="large" v-decorator="['email',{ rules: [{ required: true, message: '请输入邮箱!' }] }]" placeholder="请输入邮箱">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input size="large" v-decorator="['password',{ rules: [{ required: true, message: '请输入密码!' }] }]" type="password" autocomplete="false" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" size="large" html-type="submit" class="login-form-button" :loading="loading" :disabled="btnValue">
                        登录
                    </a-button>
                </a-form-item>
            </a-form>
            <div class="copyright-info">版权所有：后台管理中心</div>
        </div>
    </div>
</template>

<script>
    import { Login,checkToken } from "@/request/requests";
    export default {
        name: "Login",
        data() {
            return {
                form: this.$form.createForm(this),
                loading: false,
                btnValue:true
            };
        },
        created() {
            this.initData();
        },
        methods: {
            async handleLogin(e) {
                e.preventDefault();
                this.form.validateFields(
                    null,
                    { force: true },
                    async (errors) => {
                        if (errors) {
                            return;
                        }
                        this.loading = true;
                        let formValues = this.form.getFieldsValue();
                        let result = await Login(formValues);
                        const usertype = result.data.userInfo.usertype
                        if( usertype ==23){
                            this.$notification.error({
                                message: "登录失败",
                                description: 'error'
                            });
                            this.loading = false;
                            return ;
                        }
                        if (result.status != "200" ) {
                            this.$notification.error({
                                message: "登录失败",
                                description: result.msg
                            });
                            this.loading = false;
                            return;
                        }
                        this.loading = false;
                        this.loginRes(result);
                    }
                );
            },
            initData:async function () {
                window.console.log("---------login")
                let now = new Date().getTime();
                let deadline = sessionStorage.getItem("deadline");
                let token = sessionStorage.getItem("token");
                let userInfo = sessionStorage.getItem("userInfo");
                if (!deadline||!token||!userInfo){
                    this.btnValue=false;
                }else {
                    if (deadline > now) {
                        /**
                         * 请求会出现401  比如注销的token
                         */
                        try {
                            let res = await checkToken({token: token});
                            if (res.status != "200") {
                                this.btnValue = false;
                            } else {
                                this.$message.success(
                                    '成功返回首页',
                                    1
                                );
                                this.$router.push({name: "backhome"});
                            }
                        }catch(err){
                            this.btnValue = false;
                            window.console.log("------------------"+err)
                        }
                    } else {
                        this.btnValue = false;
                    }
                }
            },
            loginRes:function (result) {
                let { userInfo, token, deadline,menu } = result.data;
                let { name: name } = userInfo;
                sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("deadline", Date.parse(deadline));
                sessionStorage.setItem("menuList", JSON.stringify(menu));
                this.$notification.success({
                    message: "登录成功",
                    description: `欢迎回来，${name}`
                });
                this.$router.push({ name: "backhome" });
            }
        }
    };
</script>

<style lang="less" scoped>
    .container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: auto;
        background-image: url(https://kilogodsfile.oss-cn-shenzhen.aliyuncs.com/loginback1.jpg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 150px 0;
        background-color: rgb(240, 242, 245);

        h3 {
            text-align: center;
            font-size: 20px;
            font-weight: bold;

            .logo {
                vertical-align: text-top;
                border-style: none;
                height: 35px;
            }
        }

        .login-form {
            width: 70%;
            max-width: 500px;
            // height: 100%;
            margin: 0 auto;

            .login-form-button {
                width: 100%;
            }

            .login-form-remember {
                float: left;
            }
        }

        .copyright-info {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            text-align: center;
            margin: 18px 0;
        }
    }
</style>
