import Login from '@/views/Login'
import Layout from '@/components/Layout/Layout'
const routeMap = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {title: '后台管理中心'}
    },
    {
        path: '/backhome',
        component: Layout,
        name: 'backhome',
        redirect: '/backhome/page',
        children: [
            {
                path: 'page',
                name: 'page',
                component: () => import('@/components/page/page'),
                meta: { title: '首页' }
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('@/components/user/User'),
                meta: { title: '用户表' }
            },
            {
                path: 'loginlog',
                name: 'loginlog',
                component: () => import('@/components/loginlog/LoginLog'),
                meta: { title: '登录日志' }
            },
            {
                path: 'updatepwd',
                name: 'updatepwd',
                component: () => import('@/components/UpdPwd/updatePwd'),
                meta: { title: '修改密码' }
            },
            {
                path: 'sysmenu',
                name: 'sysmenu',
                component: () => import('@/components/sysmenu/SysMenu'),
                meta: { title: '菜单' }
            },
            {
                path: 'sysrole',
                name: 'sysrole',
                component: () => import('@/components/sysrole/SysRole'),
                meta: { title: '角色' }
            },
            {
                path: 'sysrolemenu',
                name: 'sysrolemenu',
                component: () => import('@/components/sysrolemenu/SysRoleMenu'),
                meta: { title: '角色菜单' }
            },
            {
                path: 'sysuserrole',
                name: 'sysuserrole',
                component: () => import('@/components/sysuserrole/SysUserRole'),
                meta: { title: '用户角色' }
            },
            {
                path: 'selfuser',
                name: 'selfuser',
                component: () => import('@/components/user/selfUser'),
                meta: { title: '个人信息' }
            },
            {
                path: 'carbuy',
                name: 'carbuy',
                component: () => import('@/components/carbuy/Carbuy'),
                meta: { title: '车辆购买' }
            },
            {
                path: 'cardinfo',
                name: 'cardinfo',
                component: () => import('@/components/cardinfo/Cardinfo'),
                meta: { title: '卡片信息' }
            },
            {
                path: 'carinfo',
                name: 'carinfo',
                component: () => import('@/components/carinfo/Carinfo'),
                meta: { title: '车辆信息' }
            },
            {
                path: 'commission',
                name: 'commission',
                component: () => import('@/components/commission/Commission'),
                meta: { title: '佣金列表' }
            },
            {
                path: 'countryinfo',
                name: 'countryinfo',
                component: () => import('@/components/countryinfo/Countryinfo'),
                meta: { title: '国家信息' }
            },
            {
                path: 'discountcode',
                name: 'discountcode',
                component: () => import('@/components/discountcode/Discountcode'),
                meta: { title: '折扣码' }
            },
            {
                path: 'excode',
                name: 'excode',
                component: () => import('@/components/excode/Excode'),
                meta: { title: '兑换码' }
            },
            {
                path: 'goodssku',
                name: 'goodssku',
                component: () => import('@/components/goodssku/GoodsSku'),
                meta: { title: '商品套餐' }
            },
            {
                path: 'goodsspu',
                name: 'goodsspu',
                component: () => import('@/components/goodsspu/GoodsSpu'),
                meta: { title: '商品信息' }
            },
            {
                path: 'goodstype',
                name: 'goodstype',
                component: () => import('@/components/goodstype/Goodstype'),
                meta: { title: '商品类型' }
            },
            {
                path: 'introlevel',
                name: 'introlevel',
                component: () => import('@/components/introlevel/Introlevel'),
                meta: { title: '推荐等级设置' }
            },
            {
                path: 'message',
                name: 'message',
                component: () => import('@/components/message/Message'),
                meta: { title: '留言信息' }
            },
            {
                path: 'orderinfo',
                name: 'orderinfo',
                component: () => import('@/components/orderinfo/Orderinfo'),
                meta: { title: '订单信息' }
            },
            {
                path: 'paytype',
                name: 'paytype',
                component: () => import('@/components/paytype/Paytype'),
                meta: { title: '支付方式' }
            },
            {
                path: 'prizepool',
                name: 'prizepool',
                component: () => import('@/components/prizepool/Prizepool'),
                meta: { title: '奖品抽奖池' }
            },
            {
                path: 'riskcontent',
                name: 'riskcontent',
                component: () => import('@/components/riskcontent/Riskcontent'),
                meta: { title: '风控内容描述' }
            },
            {
                path: 'risklist',
                name: 'risklist',
                component: () => import('@/components/risklist/Risklist'),
                meta: { title: '风控名单' }
            },
            {
                path: 'signininfo',
                name: 'signininfo',
                component: () => import('@/components/signininfo/Signininfo'),
                meta: { title: '签到信息' }
            },
            {
                path: 'systemconfig',
                name: 'systemconfig',
                component: () => import('@/components/systemconfig/Systemconfig'),
                meta: { title: '配置信息' }
            },
            {
                path: 'ticket',
                name: 'ticket',
                component: () => import('@/components/ticket/Ticket'),
                meta: { title: '车票信息' }
            },
            {
                path: 'Overduetickets',
                name: 'Overduetickets',
                component: () => import('@/components/Overduetickets/Ticket'),
                meta: { title: '过期车票' }
            },
            {
                path: 'userexcode',
                name: 'userexcode',
                component: () => import('@/components/userexcode/Userexcode'),
                meta: { title: '用户兑换码' }
            },
            {
                path: 'winprize',
                name: 'winprize',
                component: () => import('@/components/winprize/Winprize'),
                meta: { title: '抽奖记录' }
            },
            {
                path: 'withdrawal',
                name: 'withdrawal',
                component: () => import('@/components/withdrawal/Withdrawal'),
                meta: { title: '提现申请记录' }
            },
            {
                path: 'wordertype',
                name: 'wordertype',
                component: () => import('@/components/wordertype/Wordertype'),
                meta: { title: '工单类型' }
            },
            {
                path: 'workorder',
                name: 'workorder',
                component: () => import('@/components/workorder/Workorder'),
                meta: { title: '工单' }
            },
            {
                path: 'helpdetail',
                name: 'helpdetail',
                component: () => import('@/components/helpdetail/Helpdetail'),
                meta: { title: '帮助详情' }
            },
            {
                path: 'helptype',
                name: 'helptype',
                component: () => import('@/components/helptype/Helptype'),
                meta: { title: '帮助分类' }
            },
            {
                path: 'payorder',
                name: 'payorder',
                component: () => import('@/components/payorder/Payorder'),
                meta: { title: '支付订单' }
            },
            {
                path: 'province',
                name: 'province',
                component: () => import('@/components/province/Province'),
                meta: { title: '省份信息' }
            },
            {
                path: 'usercard',
                name: 'usercard',
                component: () => import('@/components/usercard/Usercard'),
                meta: { title: '用户信用卡' }
            },
            {
                path: 'orderrefund',
                name: 'orderrefund',
                component: () => import('@/components/orderrefund/Orderrefund'),
                meta: { title: '订单退款' }
            },
            {
                path: 'userexcodegive',
                name: 'userexcodegive',
                component: () => import('@/components/userexcodegive/Userexcodegive'),
                meta: { title: '兑换码赠送' }
            },
            {
                path: 'binduser',
                name: 'binduser',
                component: () => import('@/components/binduser/Binduser'),
                meta: { title: '员工绑定用户' }
            },
            {
                path: 'ticketpwd',
                name: 'ticketpwd',
                component: () => import('@/components/ticketpwd/Ticketpwd'),
                meta: { title: '车票密码' }
            },

        ]
    },
    {   name: '404',
        path: '/404',
        hidden: true,
        component: () => import('@/views/404'),
    },
    {
        name: '403',
        path: '/403',
        hidden: true,
        component: () => import('@/views/403'),
    },
    {
        path: '*', redirect: '/404', hidden: true
    }
]
export default routeMap;
